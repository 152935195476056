<template>
  <v-dialog v-model="dialog" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly" :persistent="step > 0">
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title class="warning white--text px-3">
        <span class="headline">Arquivar Projeto</span>
        <v-spacer />
        <v-btn icon color="white" @click="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4 pb-0" style="text-align: justify;">
        <p>
          <strong>Atenção!</strong> Arquivar o projeto "<strong>{{ project.name }}</strong>" irá desligar todas as instâncias de suas aplicações (<i>builds</i>) e desativar o site de documentação
          pública do projeto e o site de documentação da API. Além disso, todos os membros da equipe perderão acesso ao projeto por meio deste
          painel, no GitLab, no Sentry, no Matomo e nas demais ferramentas do <strong>Embrapa I/O</strong>.
        </p>
        <p>
          Não é possível efetuar <i>backup</i> de qualquer dado de um projeto arquivado. Assim, <strong>é fortemente recomendado
          efetuar <i>backup</i> das <i>builds</i>, de todo o código-fonte, da documentação, de artefatos diversos, do monitoramento de erros e de eventuais relatórios <u>antes de
          executar esta ação</u></strong>.
        </p>
      </v-card-text>

      <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-4">
        {{ message }}
      </v-alert>

      <v-window v-model="step">
        <v-window-item>
          <v-card-actions v-if="!$vuetify.breakpoint.xsOnly" class="px-4">
            <v-switch v-model="aware" label="Estou ciente e quero continuar." inset class="ml-2"/>

            <v-spacer />

            <v-btn
              :disabled="!aware"
              color="warning white--text"
              depressed
              x-large
              @click="sendArchivePin()"
              :loading="saving"
            >
              Prosseguir
              <v-icon class="ml-1">
                arrow_forward
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <v-row wrap>
              <v-col cols="12">
                <v-switch v-model="aware" label="Estou ciente e quero continuar." inset class="ml-2"/>
              </v-col>
              <v-col cols="12">
                <v-btn
                  :disabled="!aware"
                  color="warning white--text"
                  depressed
                  x-large
                  @click="sendArchivePin()"
                  block
                  :loading="saving"
                >
                  Prosseguir
                  <v-icon class="ml-1">
                    arrow_forward
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-window-item>
        <v-window-item>
          <v-card-text class="text-justify red darken-2 white--text">
            Um número de 6 dígitos foi enviado para o e-mail "<strong>{{ user.email }}</strong>". Se tiver certeza de que quer continuar, insira-o abaixo:
          </v-card-text>
          <div class="input-wrapper my-5" style="width: 280px; margin: 0 auto;">
            <v-otp-input v-model="pin" length="6" />
          </div>
          <v-card-actions class="px-4">
            <v-btn
              v-show="!$vuetify.breakpoint.xsOnly"
              color="error"
              text
              @click="cancel()"
            >
              Cancelar
            </v-btn>

            <v-spacer v-show="!$vuetify.breakpoint.xsOnly" />

            <v-btn
              :disabled="!aware"
              color="warning white--text"
              depressed
              x-large
              @click="archive()"
              :block="$vuetify.breakpoint.xsOnly"
              :loading="saving"
            >
              Arquivar
              <v-icon class="ml-1">
                archive
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

import EmbrapaHelper from '@/helpers/embrapa'
import ErrorHelper from '@/helpers/error'

export default {
  mixins: [
    EmbrapaHelper,
    ErrorHelper
  ],
  data: () => ({
    dialog: false,
    user: null,
    project: {
      name: '',
      unix: '',
      maintainer: false
    },
    aware: false,
    pin: '',
    saving: false,
    error: false,
    message: '',
    step: 0
  }),
  beforeMount () {
    this.user = this.$localStorage.get('user')

    this.reload()
  },
  mounted () {
    if (this.user.authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  watch: {
    dialogEdit (val) {
      val || this.closeEdit()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    open (project) {
      this.reload()

      this.project.name = project.name
      this.project.unix = project.unix
      this.project.maintainer = project.maintainer

      this.dialog = true
    },
    reload () {
      this.saving = false

      this.project.name = ''
      this.project.unix = ''
      this.project.maintainer = false

      this.step = 0
      this.pin = ''
      this.aware = false
      this.error = false
      this.message = ''
    },
    cancel () {
      this.reload()

      this.dialog = false
    },
    sendArchivePin () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.saving = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.saving = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000, headers: this.headers }).then(response => {
        axios.post(api + '/project/archive/pin', { project: this.project.unix }, { headers: this.headers }).then(response => {
          this.saving = false

          this.step = 1
        }).catch(err)
      }).catch(err)
    },
    archive () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.saving = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.saving = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000, headers: this.headers }).then(response => {
        axios.post(api + '/project/archive/confirm', { project: this.project.unix, pin: this.pin }, { headers: this.headers }).then(response => {
          this.$emit('message', 'Projeto "' + this.project.name + '" arquivado com sucesso!', 'success')

          this.$emit('refresh')

          this.reload()

          this.dialog = false
        }).catch(err)
      }).catch(err)
    }
  }
}
</script>
