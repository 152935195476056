<template>
  <v-card outlined color="white">
    <v-data-table
      :headers="headers"
      :items="environment.volumes"
      hide-default-footer
      :page.sync="page"
      :items-per-page="10"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Volumes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogEdit" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>add</v-icon>
                Novo Volume
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form v-model="validate">
                  <v-text-field
                    v-model="editedItem.name"
                    label="nome"
                    :prefix="prefix"
                    :rules="rrules()"
                    outlined
                    single-line
                  />
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="closeEdit"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="success"
                  @click="save"
                  :disabled="!validate"
                >
                  Adicionar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="450px">
            <v-card>
              <v-card-title class="text-h5">Tem certeza que deseja apagar este volume?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="info" text @click="closeDelete">Não</v-btn>
                <v-btn color="error" text @click="deleteConfirm">Sim</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-spacer />
        <v-icon
          small
          @click="deleteItem(item)"
          :disabled="item.status !== 'DRAFT' || locked.includes(item.name)"
        >
          delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert color="warning" outlined icon="warning" class="mt-6">
          Nenhum volume configurado!
        </v-alert>
      </template>
      <template v-slot:item.name="{ item }">
        <span style="font-family: monospace;">
          {{ prefix }}
        </span>
        <v-chip label style="font-family: monospace;" class="font-weight-bold mx-0" v-if="!locked.includes(item.name)">
          {{ item.name }}
        </v-chip>
        <v-badge icon="lock" color="error" overlap bordered v-else>
          <v-chip label style="font-family: monospace;" class="font-weight-bold mx-0">
            {{ item.name }}
          </v-chip>
        </v-badge>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip label :color="item.status === 'DRAFT' ? 'info' : (item.status === 'ACTIVE' ? 'success' : 'warning')">
          <v-icon small class="mr-2">{{ item.status === 'DRAFT' ? 'edit' : (item.status === 'ACTIVE' ? 'done' : 'drive_folder_upload') }}</v-icon>
          {{ item.status === 'DRAFT' ? 'Editando' : (item.status === 'ACTIVE' ? 'Ativo' : 'Criando') }}
        </v-chip>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      />
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    environment: {
      type: Object,
      require: true
    },
    project: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    app: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    env: {
      type: String,
      require: true,
      default: ''
    }
  },
  data: () => ({
    prefix: '',
    dialogEdit: false,
    dialogDelete: false,
    page: 1,
    pageCount: 0,
    headers: [
      { text: 'Volume', align: 'start', value: 'name', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: '', align: 'end', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      status: 'DRAFT'
    },
    defaultItem: {
      name: '',
      status: 'DRAFT'
    },
    validate: false,
    locked: ['backup']
  }),
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Novo Volume' : 'Editar Volume'
    }
  },
  watch: {
    dialogEdit (val) {
      val || this.closeEdit()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  beforeMount () {
    this.load()
  },
  beforeUpdate () {
    this.load()
  },
  methods: {
    load () {
      this.prefix = this.app.repository.replace('/', '_') + '_' + this.env + '_'

      this.locked.forEach(name => {
        if (!this.environment.volumes.find(v => v.name === name)) {
          this.environment.volumes.push({
            name,
            status: 'DRAFT'
          })
        }
      })
    },
    editItem (item) {
      this.editedIndex = this.environment.volumes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },

    deleteItem (item) {
      this.editedIndex = this.environment.volumes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteConfirm () {
      this.environment.variables.forEach((a, index, arr) => {
        if (a.type === 'VOLUME' && a.value === this.prefix + this.environment.volumes[this.editedIndex].name) arr.splice(index, 1)
      })

      this.environment.volumes.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    closeEdit () {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.environment.volumes[this.editedIndex], this.editedItem)
      } else {
        this.editedItem.status = 'DRAFT'
        this.environment.volumes.push(this.editedItem)
      }
      this.closeEdit()
    },
    rrules () {
      return [
        v => !!v || 'Não pode ser vazio!',
        v => !v || /^[a-z0-9]+$/.test(v) || 'Utilize apenas letras minúsculas e números!',
        v => v.length > 1 || 'Muito curto!',
        v => this.environment.volumes.filter(i => i.name === v).length === 0 || 'Este nome já está em uso!'
      ]
    }
  }
}
</script>
