<template>
  <v-dialog v-model="dialog" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title class="purple white--text px-3">
        <span class="headline">Equipe</span>
        <v-spacer />
        <v-btn color="info" @click="copy()" class="mr-2">
          <v-icon>copy_all</v-icon>
        </v-btn>
        <v-btn color="warning" @click="mail()" class="mr-2">
          <v-icon>mail</v-icon>
        </v-btn>
        <v-btn color="white" @click="dialogEdit = true" :disabled="!project.maintainer" v-if="$vuetify.breakpoint.xsOnly">
          <v-icon>person_add</v-icon>
        </v-btn>
        <v-btn color="white" @click="dialogEdit = true" :disabled="!project.maintainer" v-else>
          <v-icon class="mr-2">person_add</v-icon>
          Adicionar
        </v-btn>
        <v-btn icon class="hidden-sm-and-up ml-2" color="white" @click="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="columns"
        :items="team"
        sort-desc
        hide-default-header
        disable-pagination
        fixed-header
        hide-default-footer
        class="elevation-0"
        light
      >
        <template v-slot:item.role="props">
          <v-edit-dialog
            v-if="project.maintainer && props.item.email !== user.email"
            :return-value.sync="props.item.role"
            large
            save-text="Alterar"
            cancel-text="Cancelar"
          >
            <v-chip
              :color="props.item.role === 'Maintainer' ? 'teal' : 'info'"
              label
              outlined>
              <v-icon small :color="props.item.role === 'Maintainer' ? 'teal' : 'info'" class="mr-2">{{ props.item.role === 'Maintainer' ? 'engineering' : 'person' }}</v-icon>
              {{ props.item.role === 'Maintainer' ? 'Architect' : 'Outro' }}
            </v-chip>
            <template v-slot:input>
              <v-radio-group v-model="props.item.role" mandatory>
                <v-radio value="Developer" label="Manager, Engineer, Analyst, Specialist ou Programmer" />
                <v-radio value="Maintainer" label="Architect (Arquiteto da Solução)" :disabled="!isEmbrapa(props.item.email)" />
              </v-radio-group>
            </template>
          </v-edit-dialog>
          <v-chip
            v-else
            :color="props.item.role === 'Maintainer' ? 'teal' : 'info'"
            label
            outlined>
            <v-icon small :color="props.item.role === 'Maintainer' ? 'teal' : 'info'" class="mr-2">{{ props.item.role === 'Maintainer' ? 'engineering' : 'person' }}</v-icon>
            {{ props.item.role === 'Maintainer' ? 'Architect' : 'Outro' }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="deleteItem(item)"
            :disabled="!project.maintainer || item.email === user.email"
            color="error"
          >
            remove_circle_outline
          </v-icon>
        </template>
      </v-data-table>

      <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-4">
        {{ message }}
      </v-alert>

      <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
        <v-btn
          color="error"
          text
          @click="cancel()"
        >
          Cancelar
        </v-btn>

        <v-spacer />

        <v-btn
          color="success white--text"
          depressed
          x-large
          @click="save()"
          :loading="saving"
        >
          Salvar
          <v-icon class="ml-1">
            done
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-row wrap>
          <v-col cols="12">
            <v-btn
              color="success white--text"
              depressed
              x-large
              @click="save()"
              block
              :loading="saving"
            >
              Salvar
              <v-icon class="ml-1">
                done
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog
        v-model="dialogEdit"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Adicionar Membro</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-container>
              <v-form v-model="validate">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.email"
                      label="e-Mail"
                      :rules="rules"
                      outlined
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group v-model="editedItem.role" mandatory class="my-0 py-0">
                      <v-radio value="Developer" label="Manager, Engineer, Analyst, Specialist ou Programmer" />
                      <v-radio value="Maintainer" label="Arquiteto da Solução (Architect)" :disabled="!isEmbrapa(editedItem.email)" />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-alert text type="warning" class="mx-4 mt-0">
            <b>Atenção!</b> Observe o papel dos membros da equipe na
            <a href="https://www.embrapa.io/docs/squads/" target="_blank">documentação da plataforma</a>.
          </v-alert>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="closeEdit()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              @click="addMember()"
              :disabled="!validate"
            >
              <v-icon class="mr-2">done</v-icon>
              Adicionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="300px">
        <v-card>
          <v-card-title class="text-h5">Remover o membro da equipe?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" text @click="closeDelete">Não</v-btn>
            <v-btn color="error" @click="deleteItemConfirm">Sim</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-dialog>
</template>

<script>
import axios from 'axios'

import EmbrapaHelper from '@/helpers/embrapa'
import ErrorHelper from '@/helpers/error'

export default {
  mixins: [
    EmbrapaHelper,
    ErrorHelper
  ],
  data: () => ({
    dialog: false,
    user: null,
    project: {
      name: '',
      unix: '',
      maintainer: false
    },
    team: [],
    dialogEdit: false,
    dialogDelete: false,
    columns: [
      { text: 'e-Mail', align: 'start', value: 'email' },
      { text: '', value: 'role', sortable: false },
      { text: '', value: 'actions', sortable: false }
    ],
    saving: false,
    headers: {},
    error: false,
    message: '',
    editedIndex: -1,
    editedItem: {
      email: '',
      role: ''
    },
    defaultItem: {
      email: '',
      role: 'Developer'
    },
    rules: [
      v => !!v || 'Não pode ser vazio!',
      v => !v || /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) || 'O e-mail precisa ser válido!'
    ],
    validate: false
  }),
  beforeMount () {
    this.user = this.$localStorage.get('user')

    this.reload()
  },
  mounted () {
    if (this.user.authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  watch: {
    dialogEdit (val) {
      val || this.closeEdit()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    open (project) {
      this.reload()

      this.project.name = project.name
      this.project.unix = project.unix
      this.project.maintainer = project.maintainer

      this.team = JSON.parse(JSON.stringify(project.team))

      this.dialog = true
    },
    reload () {
      this.saving = false

      this.project.name = ''
      this.project.unix = ''
      this.project.maintainer = false

      this.team = []

      this.error = false
      this.message = ''
    },
    cancel () {
      this.reload()

      this.dialog = false
    },
    save () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.saving = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.saving = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        const data = {
          unix: this.project.unix,
          team: this.team
        }

        axios.post(api + '/team', data, { headers: this.headers }).then(response => {
          this.saving = false

          this.$emit('message', 'Equipe do projeto "' + this.project.name + '" atualizada com sucesso!', 'success')

          this.$emit('refresh')

          this.reload()

          this.dialog = false
        }).catch(err)
      }).catch(err)
    },
    deleteItem (item) {
      if (!this.project.maintainer) return

      this.editedIndex = this.team.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.team.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    closeEdit () {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    addMember () {
      if (!this.team.find(m => m.email === this.editedItem.email)) {
        this.team.push(this.editedItem)
      }

      this.closeEdit()
    },
    copy () {
      const emails = this.team.map(m => m.email).join(', ')

      navigator.clipboard.writeText(emails).then(() => {
        this.$emit('message', 'Lista de e-mails copiada para a área de transferência!', 'success')
      }).catch(() => {
        this.$emit('message', 'Não foi possível copiar a lista de e-mails para a área de transferência!', 'error')
      })
    },
    mail () {
      const emails = this.team.filter(m => m.email !== this.user.email).map(m => m.email).join(';')

      window.open('mailto:' + emails + '?subject=' + encodeURIComponent('Embrapa I/O: Sobre o projeto "' + this.project.name + '"'))
    }
  }
}
</script>
