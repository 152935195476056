<template>
  <v-app>

    <v-app-bar app clipped-left dark color="primary">
      <v-toolbar-title class="pl-0 pt-2">
        <img src="@/assets/embrapa.io-white.png" style="height: 30px;" alt="Logo do Embrapa I/O">
      </v-toolbar-title>

      <v-progress-linear
        :active="syncing"
        :indeterminate="syncing"
        absolute
        bottom
        color="purple"
      />

      <v-spacer />

      <v-menu bottom center v-if="!$vuetify.breakpoint.smAndDown">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
          <v-badge overlap color="light-green" :content="counter.toString()"><v-icon>style</v-icon></v-badge>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-if="counter < 5" @click.stop.prevent="selectAll()">
            <v-list-item-icon class="mr-2">
              <v-icon>style</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Todos
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small color="success">library_add_check</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-else @click.stop.prevent="selectNone()">
            <v-list-item-icon class="mr-2">
              <v-icon>style</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Nenhum
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small color="grey">filter_none</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
          <div v-for="e in ecosystems" :key="e.name">
            <v-divider v-if="e.name === 'corporate'" />
            <v-list-item @click.stop.prevent="filter(e.name)">
              <v-list-item-icon class="mr-2">
                <v-icon>{{ e.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ e.short }}
              </v-list-item-content>
              <v-list-item-action>
                <v-icon small :color="isSelected(e.name) ? 'success' : 'grey'">{{ isSelected(e.name) ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>

      <v-text-field append-icon="search" label="Buscar..." single-line hide-details class="hidden-sm-and-down ml-2" color="white" v-model="search" />

      <v-btn icon class="ml-2" @click="$router.push('/about')"><v-icon>info_outline</v-icon></v-btn>

      <v-btn icon href="https://www.embrapa.io" target="_blank"><v-icon>help_outline</v-icon></v-btn>

      <v-btn large color="light-green" class="white--text hidden-sm-and-down ml-2" @click.stop="wizard.project = true" :disabled="!embrapa">
        <v-icon left dark>
          add
        </v-icon>
        Novo Projeto
      </v-btn>

      <v-btn icon class="ml-2" @click="refresh()" :loading="syncing"><v-icon>sync</v-icon></v-btn>

      <div class="d-flex align-center ml-2" style="margin-left: auto; width: 320px;" v-if="!$vuetify.breakpoint.smAndDown">
        <user-wrapper @message="message" />
      </div>
    </v-app-bar>

    <v-main>
      <div class="hidden-md-and-up">
        <user-wrapper @message="message" />
        <v-divider />
      </div>
      <v-container fluid grid-list-lg>
        <v-row no-gutters>
          <v-col cols="12">
            <v-alert color="error" icon="warning" dark v-show="error.active">{{ error.message }}</v-alert>
          </v-col>

          <v-col cols="2" sm="2" class="hidden-md-and-up mt-0 pb-0 pt-1 mb-4">
            <v-menu bottom center>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                <v-badge overlap color="light-green" :content="counter.toString()"><v-icon>style</v-icon></v-badge>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-if="counter < 5" @click.stop.prevent="selectAll()">
                  <v-list-item-icon class="mr-2">
                    <v-icon>folder_copy</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Todos
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon small color="success">library_add_check</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-else @click.stop.prevent="selectNone()">
                  <v-list-item-icon class="mr-2">
                    <v-icon>folder_copy</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Nenhum
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon small color="grey">filter_none</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
                <div v-for="e in ecosystems" :key="e.name">
                  <v-divider v-if="e.name === 'corporate'" />
                  <v-list-item @click.stop.prevent="filter(e.name)">
                    <v-list-item-icon class="mr-2">
                      <v-icon>{{ e.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ e.short }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small :color="isSelected(e.name) ? 'success' : 'grey'">{{ isSelected(e.name) ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="10" sm="10" class="hidden-md-and-up mt-0 pt-0 pb-1 mb-0">
            <v-text-field append-icon="search" label="Buscar..." single-line hide-details v-model="search" class="mt-0 pt-0" />
          </v-col>

          <v-col cols="12" sm="12" class="hidden-md-and-up mt-0 pt-0 mb-4" v-show="embrapa">
            <v-btn large block color="light-green" class="white--text" @click.stop="wizard.project = true" :disabled="!embrapa">
              <v-icon left dark>
                add
              </v-icon>
              Novo Projeto
            </v-btn>
          </v-col>

          <v-col v-if="projects.length === 0" class="hidden-md-and-up">
            <v-img src="@/assets/empty.png" height="284" contain class="my-6" />
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="p in filtered" :key="p['_id']">
            <v-card class="mb-4 mx-1">
              <v-card-title primary-title>
                <div class="headline" style="overflow: hidden; white-space: nowrap; max-width: 60%;">{{ p.name }}</div>
                <v-spacer />
                <v-btn icon :href="gitlab + '/' + p.unix" target="_blank"><v-icon>code</v-icon></v-btn>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon v-if="hasEcosystem(p)">more_vert</v-icon>
                      <v-badge v-else overlap dot color="warning"><v-icon>more_vert</v-icon></v-badge>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-subheader>Documentação</v-subheader>
                    <v-list-item @click="website(p)" :disabled="p.support.web !== 'ACTIVE'">
                      <v-list-item-icon class="mr-2">
                        <v-icon>language</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Website
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small :color="p.support.web === 'ACTIVE' ? 'success' : 'error'">{{ p.support.web === 'ACTIVE' ? 'cloud_queue' : 'cloud_off' }}</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="api(p)" :disabled="p.support.api !== 'ACTIVE'">
                      <v-list-item-icon class="mr-2">
                        <v-icon>hub</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        API
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small :color="p.support.web === 'ACTIVE' ? 'success' : 'error'">{{ p.support.api === 'ACTIVE' ? 'cloud_queue' : 'cloud_off' }}</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                    <v-subheader>Configurações</v-subheader>
                    <v-list-item @click="projectTeam(p)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>people_alt</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Equipe
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="projectSettings(p)" :disabled="!embrapa || !p.maintainer">
                      <v-list-item-icon class="mr-2">
                        <v-icon>tune</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Repositórios
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-subheader v-if="hasEcosystem(p)">Ecossistemas</v-subheader>
                    <v-badge v-else inline color="warning" icon="priority_high"><v-subheader>Ecossistemas</v-subheader></v-badge>
                    <v-list-item v-for="e in ecosystems" :key="e.name"  @click.stop.prevent="ecosystem(p, e.name)" :disabled="syncing">
                      <v-list-item-icon class="mr-2">
                        <v-icon>{{ e.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        {{ e.short }}
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small :color="p.ecosystems[e.name] ? 'success' : 'grey'">{{ p.ecosystems[e.name] ? 'radio_button_checked' : 'radio_button_unchecked' }}</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                    <v-subheader>Avançado</v-subheader>
                    <v-list-item @click="projectArchive(p)" :disabled="!embrapa || !p.maintainer">
                      <v-list-item-icon class="mr-2">
                        <v-icon>archive</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Arquivar
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-subtitle style="height: 40px;" class="pt-1">
                <div :style="'font-family: monospace; float: left; overflow: hidden; white-space: nowrap;' + (embrapa && p.maintainer ? ' max-width: 60%;' : '')" class="font-weight-bold info--text">{{ p.unix }}</div>
                <v-spacer />
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" small dark v-if="embrapa && p.maintainer" color="pink lighten-4" style="float: right;" class="overline">
                      <v-icon small>engineering</v-icon>
                    </v-chip>
                  </template>
                  <span>Arquiteto da Solução</span>
                </v-tooltip>
              </v-card-subtitle>
              <v-divider />
              <apps-small-wrapper :project="p" :boilerplates="boilerplates" :clusters="clusters" :types="types" @message="message" @refresh="refresh" @highlight="highlight" v-if="p.apps.length > 0" />
              <v-img src="@/assets/empty.png" height="250" contain class="my-6" v-else />
              <v-card-actions class="px-2">
                <v-row wrap class="pb-3" v-if="$vuetify.breakpoint.smAndDown">
                  <v-col cols="12" class="py-1">
                    <v-btn color="purple" class="white--text ma-0" style="min-width: 60px;" block large depressed @click="wizardApp(p)" :disabled="!embrapa || !p.maintainer">
                      <v-icon dark class="mr-2">
                        auto_fix_high
                      </v-icon> Nova App
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <v-btn color="warning" class="white--text ma-0" style="min-width: 60px;" block large depressed :disabled="pannel[p.unix] === null || !('hit' in p.apps[pannel[p.unix]])" @click="analytics(p.apps[pannel[p.unix]])">
                      <v-icon dark class="mr-2">
                        poll
                      </v-icon> Painel
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row wrap class="pb-3" v-else>
                  <v-col cols="6" class="py-1 pr-1">
                    <v-btn color="warning" class="white--text ma-0" style="min-width: 60px;" block large depressed :disabled="pannel[p.unix] === null || !('hit' in p.apps[pannel[p.unix]])" @click="analytics(p.apps[pannel[p.unix]])">
                      <v-icon dark class="mr-2">
                        poll
                      </v-icon> Painel
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="py-1 pl-1">
                    <v-btn color="purple" class="white--text ma-0" style="min-width: 60px;" block large depressed @click="wizardApp(p)" :disabled="!embrapa || !p.maintainer">
                      <v-icon dark class="mr-2">
                        auto_fix_high
                      </v-icon> Nova App
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" class="px-2" v-if="projects && projects.length > 0 && filtered.length === 0">
            <v-alert :value="true" type="warning">
              Sua busca com o termo "{{ search }}" não retornou resultados!
            </v-alert>
          </v-col>
        </v-row>

        <v-dialog v-model="wizard.project" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
          <wizard-project-wrapper @close="wizard.project = false" @refresh="refresh" @message="message" />
        </v-dialog>

        <wizard-app-wrapper ref="app" @close="wizard.app = false" @refresh="refresh" @message="message" />

        <project-settings-wrapper ref="settings" @close="wizard.settings = false" @refresh="refresh" @message="message" />
        <project-team-wrapper ref="team" @close="wizard.team = false" @refresh="refresh" @message="message" />
        <project-archive-wrapper ref="archive" @close="wizard.archive = false" @refresh="refresh" @message="message" />

        <analytics-dialog ref="analytics" v-on="$listeners" />

        <message-wrapper ref="message" />

      </v-container>
      <v-container fill-height fluid v-if="projects.length === 0" class="hidden-sm-and-down">
        <v-row align="center" justify="center">
          <v-col><v-img src="@/assets/empty.png" height="284" contain /></v-col>
        </v-row>
      </v-container>
    </v-main>

    <beta-wrapper position="left-top" />
  </v-app>
</template>
<script>
import axios from 'axios'

import ErrorHelper from '@/helpers/error'

import BetaWrapper from '@/components/BetaRelease'
import UserWrapper from '@/components/UserDialog'
import MessageWrapper from '@/components/MessageDialog.vue'
import AppsSmallWrapper from '@/components/AppsSmall'
import WizardProjectWrapper from '@/components/WizardProject'
import WizardAppWrapper from '@/components/WizardApp'
import ProjectSettingsWrapper from '@/components/ProjectSettings'
import ProjectTeamWrapper from '@/components/ProjectTeam'
import ProjectArchiveWrapper from '@/components/ProjectArchive'
import AnalyticsDialog from '@/components/AppAnalytics'

export default {
  mixins: [
    ErrorHelper
  ],
  components: {
    BetaWrapper,
    UserWrapper,
    MessageWrapper,
    AppsSmallWrapper,
    WizardProjectWrapper,
    WizardAppWrapper,
    ProjectSettingsWrapper,
    ProjectTeamWrapper,
    ProjectArchiveWrapper,
    AnalyticsDialog
  },
  data: () => ({
    sidebar: false,
    embrapa: false,
    projects: [],
    gitlab: process.env.VUE_APP_GITLAB,
    wizard: {
      project: false,
      app: false,
      settings: false,
      team: false,
      archive: false
    },
    syncing: false,
    error: {
      active: false,
      message: ''
    },
    boilerplates: [],
    clusters: {},
    types: [],
    search: '',
    pannel: {},
    ecosystems: [
      { name: 'agriculture', short: 'Agricultura', long: 'Agricultura (e fruticultura)', icon: 'compost' },
      { name: 'livestock', short: 'Pecuária', long: 'Pecuária (e criação de animais)', icon: 'pets' },
      { name: 'forestry', short: 'Florestas', long: 'Florestas (e silvicultura)', icon: 'forest' },
      { name: 'aquaculture', short: 'Aquicultura', long: 'Aquicultura (e pesca)', icon: 'sailing' },
      { name: 'industry', short: 'Indústria', long: 'Indústria de processamento', icon: 'factory' },
      { name: 'corporate', short: 'Institucional', long: 'Institucional', icon: 'business' }
    ],
    selected: []
  }),
  beforeMount () {
    axios.get(process.env.VUE_APP_GITLAB + '/api/v4/projects/' + process.env.VUE_APP_BOILERPLATE + '/repository/files/boilerplates.json/raw?ref=main')
      .then(response => {
        this.boilerplates = response.data
      })
      .catch(err => { console.log(err) })

    axios.get(process.env.VUE_APP_GITLAB + '/api/v4/projects/' + process.env.VUE_APP_BOILERPLATE + '/repository/files/clusters.json/raw?ref=main')
      .then(response => {
        this.clusters = response.data
      })
      .catch(err => { console.log(err) })

    axios.get(process.env.VUE_APP_GITLAB + '/api/v4/projects/' + process.env.VUE_APP_BOILERPLATE + '/repository/files/orchestrators.json/raw?ref=main')
      .then(response => {
        this.types = response.data
      })
      .catch(err => { console.log(err) })
  },
  mounted () {
    if (!this.$localStorage.get('user').authenticated) {
      this.$router.push({ path: '/' })
    } else {
      this.embrapa = this.$localStorage.get('user').embrapa

      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }

      this.refresh()
    }
  },
  methods: {
    refresh () {
      this.error.active = false

      if (!navigator.onLine) {
        this.error.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error.active = true

        return
      }

      const err = error => {
        this.syncing = false

        this.error.message = this.errorMessage(error)

        this.error.active = true
      }

      this.syncing = true

      const api = process.env.VUE_APP_API

      const self = this

      console.log('#1 - Start synching...')

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        console.log('#2 - Status OK! Getting support...')

        axios.get(api + '/support', { headers: this.headers }).then(response => {
          const support = response.data

          console.log('#3 - Support OK! Getting projects...')

          axios.get(api + '/projects', { headers: this.headers }).then(response => {
            const projects = response.data

            projects.sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1

              return 0
            })

            const bugs = []
            const hits = []

            projects.forEach(p => {
              console.log('Getting project "' + p.unix + '"...')

              if (p.unix in support) p.support = support[p.unix]
              else p.support = { api: 'UNEXISTS', web: 'UNEXISTS', twa: 'UNEXISTS', bin: 'UNEXISTS', doc: 'UNEXISTS', art: 'UNEXISTS' }

              self.pannel[p.unix] = p.apps.length > 0 ? 0 : null

              bugs.push(api + '/bug/' + p.unix)
              hits.push(api + '/hit/' + p.unix)

              p.apps.forEach(a => {
                a.more = self.boilerplates.filter(b => b.unix === a.boilerplate)[0]
              })

              p.maintainer = false

              for (let i = 0; i < p.team.length; i++) {
                if (p.team[i].email !== self.$localStorage.get('user').email) continue

                if (p.team[i].role === 'Maintainer') p.maintainer = true

                break
              }
            })

            self.projects = projects

            setTimeout(() => {
              console.log('#6 - All done!')

              self.syncing = false
            }, 1000)

            console.log('#4 - Projects OK! Getting bugs and hits (async)...')

            if (bugs.length > 0) {
              Promise.all(bugs.map(b => axios.get(b, { headers: self.headers }).then(response => {
                const project = self.projects.filter(p => p.unix === response.data.project)[0]

                for (let i = 0; i < project.apps.length; i++) {
                  const unix = project.apps[i].repository.split('/')[1]

                  if (!response.data.bugs[unix]) continue

                  project.apps[i].bug = response.data.bugs[unix]
                }
              }).catch(err => {
                console.log(err)
              }))).finally(() => {
                console.log('#5.a - Bugs OK!')

                this.$forceUpdate()
              })
            }

            if (hits.length > 0) {
              Promise.all(hits.map(b => axios.get(b, { headers: self.headers }).then(response => {
                const project = self.projects.filter(p => p.unix === response.data.project)[0]

                for (let i = 0; i < project.apps.length; i++) {
                  const unix = project.apps[i].repository.split('/')[1]

                  if (!response.data.sites[unix]) continue

                  project.apps[i].hit = response.data.sites[unix]
                }
              }).catch(err => {
                console.log(err)
              }))).finally(() => {
                console.log('#5.b - Hits OK!')

                this.$forceUpdate()
              })
            }
          }).catch(err)
        }).catch(err)
      }).catch(err)
    },
    message (text, type, time) {
      this.$refs.message.open(text, type, time)
    },
    wizardApp (project) {
      this.$refs.app.open(project, this.boilerplates)
    },
    projectSettings (project) {
      this.$refs.settings.open(project)
    },
    projectTeam (project) {
      this.$refs.team.open(project)
    },
    projectArchive (project) {
      this.$refs.archive.open(project)
    },
    website (project) {
      window.open('https://' + process.env.VUE_APP_SUPPORT_WEB + '/' + project.unix, '_blank')
    },
    api (project) {
      window.open('https://' + process.env.VUE_APP_SUPPORT_API + '/' + project.unix, '_blank')
    },
    highlight (project, index) {
      this.pannel[project] = index

      this.$forceUpdate()
    },
    analytics (app) {
      this.$refs.analytics.open(app)
    },
    ecosystem (project, ecosystem) {
      const err = error => {
        this.syncing = false

        console.log(error)
      }

      this.syncing = true

      const api = process.env.VUE_APP_API

      const ecosystems = JSON.parse(JSON.stringify(project.ecosystems))

      ecosystems[ecosystem] = !ecosystems[ecosystem]

      if (ecosystems[ecosystem]) {
        if (ecosystem !== 'corporate') ecosystems.corporate = false
        else {
          for (let i = 0; i < this.ecosystems.length; i++) {
            if (this.ecosystems[i].name !== 'corporate') ecosystems[this.ecosystems[i].name] = false
          }
        }
      }

      axios.put(api + '/ecosystem/' + project.unix, ecosystems, { headers: this.headers }).then(response => {
        for (let i = 0; i < this.ecosystems.length; i++) {
          project.ecosystems[this.ecosystems[i].name] = ecosystems[this.ecosystems[i].name]
        }

        this.syncing = false
      }).catch(err)
    },
    filter (ecosystem) {
      const index = this.selected.indexOf(ecosystem)

      if (index > -1) this.selected.splice(index, 1)
      else this.selected.push(ecosystem)
    },
    isSelected (ecosystem) {
      return this.selected.indexOf(ecosystem) > -1
    },
    selectAll () {
      for (let i = 0; i < this.ecosystems.length; i++) {
        if (this.selected.indexOf(this.ecosystems[i].name) < 0) this.selected.push(this.ecosystems[i].name)
      }
    },
    selectNone () {
      this.selected = []
    },
    hasEcosystem (project) {
      for (let i = 0; i < this.ecosystems.length; i++) {
        if (project.ecosystems[this.ecosystems[i].name]) return true
      }

      return false
    }
  },
  computed: {
    filtered () {
      if ((!this.search || /^\s*$/.test(this.search)) && this.selected.length === 0) {
        return this.projects
      } else {
        const self = this

        return this.projects.filter(s => {
          return (s.name.toLowerCase().includes(self.search.toLowerCase()) ||
            s.unix.toLowerCase().includes(self.search.toLowerCase())) &&
            (self.selected.length === 0 || self.selected.filter(e => s.ecosystems[e]).length > 0)
        })
      }
    },
    counter () {
      return this.selected.length
    }
  }
}
</script>
