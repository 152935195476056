import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import storage from 'vue-localstorage'
import VueMatomo from 'vue-matomo'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCodeBranch, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'
import { faVuejs, faNodeJs, faMicrosoft, faWordpress, faPython, faAndroid, faApple, faGolang, faJava, faPhp, faAngular, faReact, faJs } from '@fortawesome/free-brands-svg-icons'

import router from './router'

library.add(
  faCodeBranch,
  faVuejs,
  faNodeJs,
  faMicrosoft,
  faWordpress,
  faPuzzlePiece,
  faPython,
  faAndroid,
  faApple,
  faGolang,
  faJava,
  faPhp,
  faAngular,
  faReact,
  faJs
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

console.log('embrapa.io/dashboard@' + process.env.VUE_APP_STAGE)
console.log('Version: ' + process.env.VUE_APP_VERSION)

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  release: 'embrapa.io@' + process.env.VUE_APP_VERSION.split('-')[0],
  environment: process.env.VUE_APP_STAGE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'dashboard.embrapa.io', /^\//]
    })
  ],
  tracesSampleRate: 1.0
})

Vue.use(storage)

Vue.use(storage, {
  name: 'ls',
  bind: true
})

const orientation = {
  portrait: false,
  landscape: false
}

const changeOrientation = () => {
  orientation.portrait = window.screen.orientation.type === 'portrait-primary'
  orientation.landscape = window.screen.orientation.type === 'landscape-primary'
}

changeOrientation()

window.addEventListener(
  'orientationchange',
  changeOrientation
)

Vue.prototype.$orientation = orientation

Vue.use(VueMatomo, {
  host: 'https://hit.embrapa.io',
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,
  router,
  preInitActions: [
    ['setCustomDimension', 1, process.env.VUE_APP_STAGE],
    ['setCustomDimension', 2, process.env.VUE_APP_VERSION]
  ]
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
