<template>
  <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
    <v-card-title
      :class="!accept ? 'error white--text' : 'success white--text'"
    >
      <span class="headline">{{ accept ? 'Criar Projeto' : 'Atenção!' }}</span>
      <v-spacer />
      <v-btn icon class="hidden-sm-and-up" color="white" @click="cancel()">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-text class="pt-4 pb-0 subtitle-1">
          Esta ferramenta irá criar a <strong>estrutura-base de um novo projeto de software</strong> aderente aos processos automatizados de DevOps
          (desenvolvimento, integração, teste e entrega contínuos).
        </v-card-text>
        <v-card-text class="pt-4 pb-0 subtitle-1">
          Neste primeiro passo será criado o projeto e, em seguida, poderão ser
          adicionadas as aplicações (apps). O projeto pode ser composto por uma única app (monolítico) ou por diversos sistemas desacoplados.
        </v-card-text>
        <v-card-text class="pt-4 pb-0 subtitle-1">
          Além disso, outros artefatos úteis, tais como o site de documentação e repositórios de apoio, poderão ser escolhidos para que o
          <strong>Embrapa I/O</strong> os crie automaticamente. Todos os recursos criados ficarão disponíveis no
          <a href="https://git.embrapa.io" target="_blank">GitLab</a> do projeto.
        </v-card-text>
        <v-card-text class="pt-4 pb-0 subtitle-1 font-weight-bold">
          Lembre-se: nossos recursos de hardware são limitados e é responsabilidade de todos os empregados o uso consciente!
        </v-card-text>
        <v-card-actions class="py-0 px-6">
          <v-switch
            v-model="accept"
            label="Estou ciente e quero continuar."
            @change="() => {
              if (!accept)
                reload()
            }"
          />
        </v-card-actions>
      </v-window-item>
      <v-window-item :value="2">
        <v-card outlined color="white">
          <v-card-text>
            <v-text-field
              outlined
              v-model="project.name"
              label="Nome do Projeto"
              hint="Exemplo: Meu Projeto"
            />

            <v-text-field
              outlined
              v-model="project.unix"
              :rules="rules"
              label="Nome Unix"
              hint="Exemplo: meu-projeto"
              @focus="suggest()"
            />

          </v-card-text>

          <v-divider />

          <v-subheader>Repositórios de Suporte</v-subheader>

          <v-card-text>
            <v-switch inset v-model="project.web" class="py-0 my-0">
              <template v-slot:label @click.stop>
                <span>Site de informações públicas do projeto.</span>
                <v-spacer />
                <v-chip color="grey" outlined label small style="font-family: monospace;">web</v-chip>
              </template>
            </v-switch>

            <v-switch inset v-model="project.api" class="py-0 my-0">
              <template v-slot:label @click.stop>
                <span>Documentação pública da API.</span>
                <v-spacer />
                <v-chip color="grey" outlined label small style="font-family: monospace;">api</v-chip>
              </template>
            </v-switch>

            <v-switch inset v-model="project.doc" class="py-0 my-0">
              <template v-slot:label @click.stop>
                <span>Documentação técnica (não-pública).</span>
                <v-spacer />
                <v-chip color="grey" outlined label small style="font-family: monospace;">doc</v-chip>
              </template>
            </v-switch>

            <v-switch inset v-model="project.art" class="py-0 my-0">
              <template v-slot:label @click.stop>
                <span>Logo e artefatos visuais.</span>
                <v-spacer />
                <v-chip color="grey" outlined label small style="font-family: monospace;">art</v-chip>
              </template>
            </v-switch>

            <v-switch inset v-model="project.bin" class="py-0 my-0">
              <template v-slot:label @click.stop>
                <span>Binários (APKs, APPXs, etc).</span>
                <v-spacer />
                <v-chip color="grey" outlined label small style="font-family: monospace;">bin</v-chip>
              </template>
            </v-switch>

            <v-switch inset v-model="project.twa" class="py-0 my-0">
              <template v-slot:label @click.stop>
                <span>Encapsulamento de PWA (com <i>Trusted Web Activity</i>).</span>
                <v-spacer />
                <v-chip color="grey" outlined label small style="font-family: monospace;">twa</v-chip>
              </template>
            </v-switch>
          </v-card-text>

          <v-divider />

          <v-subheader>Ecossistemas Digitais</v-subheader>

          <v-card-text>
            <v-switch v-for="e in eco" :key="e.name" @click="checkEcosystem(e.name)" inset v-model="ecosystems[e.name]" class="py-0 my-0">
              <template v-slot:label @click.stop>
                <span>{{ e.long }}</span>
                <v-spacer />
                <v-icon :color="e.color">{{ e.icon }}</v-icon>
              </template>
            </v-switch>
          </v-card-text>

          <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-4">
            {{ message }}
          </v-alert>
        </v-card>
      </v-window-item>
    </v-window>
    <v-spacer />
    <v-card-actions v-if="step === 1">
      <v-btn
        color="error"
        text
        @click="cancel()"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        Cancelar
      </v-btn>

      <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

      <v-btn
        color="primary white--text"
        depressed
        :disabled="!validateStep1()"
        large
        :block="$vuetify.breakpoint.xsOnly"
        @click="step++"
      >
        Próximo
        <v-icon class="ml-1">
          arrow_forward
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="step === 2 && !$vuetify.breakpoint.xsOnly">
      <v-btn
        color="error"
        text
        @click="cancel()"
      >
        Cancelar
      </v-btn>

      <v-spacer />

      <v-btn
        color="warning"
        @click="step--"
        text
      >
        <v-icon>arrow_back</v-icon>
        Voltar
      </v-btn>

      <v-btn
        color="success white--text"
        depressed
        :disabled="!validateStep2()"
        x-large
        @click="save()"
        :loading="saving"
      >
        Criar Projeto
        <v-icon class="ml-1">
          done
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="step === 2 && $vuetify.breakpoint.xsOnly">
      <v-btn
        color="success white--text"
        depressed
        :disabled="!validateStep2()"
        x-large
        @click="save()"
        block
        :loading="saving"
      >
        Criar Projeto
        <v-icon class="ml-1">
          done
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'

import ErrorHelper from '@/helpers/error'

export default {
  mixins: [
    ErrorHelper
  ],
  data: () => ({
    accept: false,
    step: 1,
    project: {
      name: '',
      unix: '',
      web: true,
      api: true,
      doc: true,
      art: true,
      bin: false,
      twa: false
    },
    saving: false,
    headers: {},
    rules: [
      v => v.length > 2 || 'Muito curto!',
      v => !v || /^[a-z0-9][a-z0-9-]+[a-z0-9]$/.test(v) || 'Utilize apenas letras minúsculas, números e hífen!',
      v => !['io', 'embrapa', 'embrapa-io', 'embrapaio', 'embrapa-br', 'embrapabr', 'test', 'teste', 'alpha', 'beta', 'release'].includes(v) || 'Palavra reservada!'
    ],
    error: false,
    message: '',
    ecosystems: {
      agriculture: false,
      livestock: false,
      forestry: false,
      aquaculture: false,
      industry: false,
      corporate: false
    },
    eco: [
      { name: 'agriculture', long: 'Agricultura (e fruticultura)', color: 'teal', icon: 'compost' },
      { name: 'livestock', long: 'Pecuária (e criação de animais)', color: 'red', icon: 'pets' },
      { name: 'forestry', long: 'Florestas (e silvicultura)', color: 'green', icon: 'forest' },
      { name: 'aquaculture', long: 'Aquicultura (e pesca)', color: 'blue', icon: 'sailing' },
      { name: 'industry', long: 'Indústria de processamento', color: 'blue-grey', icon: 'factory' },
      { name: 'corporate', long: 'Institucional', color: 'purple', icon: 'business' }
    ]
  }),
  mounted () {
    if (this.$localStorage.get('user').authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  methods: {
    reload () {
      this.step = 1
      this.accept = false
      this.saving = false

      this.project = {
        name: '',
        unix: '',
        web: true,
        api: true,
        doc: true,
        art: true,
        bin: false,
        twa: false
      }

      this.ecosystems = {
        agriculture: false,
        livestock: false,
        forestry: false,
        aquaculture: false,
        industry: false,
        corporate: false
      }
    },
    cancel () {
      this.reload()

      this.$emit('close')
    },
    checkEcosystem (eco) {
      if (eco === 'corporate') {
        if (this.ecosystems.corporate) {
          for (const key in this.ecosystems) if (key !== 'corporate') this.ecosystems[key] = false
        }
      } else this.ecosystems.corporate = false
    },
    validateEcosystems () {
      for (const key in this.ecosystems) if (this.ecosystems[key]) return true

      return false
    },
    validateStep1 () {
      return this.accept
    },
    validateStep2 () {
      return this.project.name.trim().length > 0 && this.project.unix.trim().length > 0 && this.validateEcosystems()
    },
    save () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.saving = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.saving = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        axios.post(api + '/project', this.project, { headers: this.headers }).then(response => {
          axios.put(api + '/ecosystem/' + this.project.unix, this.ecosystems, { headers: this.headers }).then(response => {
            this.$emit('message', 'Projeto "' + this.project.name + '" criado com sucesso!', 'success')

            this.$emit('refresh')

            this.reload()

            this.$emit('close')
          }).catch(err)
        }).catch(err)
      }).catch(err)
    },
    suggest () {
      if (this.project.name.trim().length > 0 && this.project.unix.trim().length === 0) {
        this.project.unix = this.project.name.trim().toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '')
      }
    }
  }
}
</script>
